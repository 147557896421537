export const adminSidebarOptions = [
  {
    title: 'sidebar.sales',
    key: 'sales',
    to: '/sales',
    icon: 'sales-chart',
  },
  {
    title: 'sidebar.merchants',
    key: 'merchants',
    to: '/merchants',
    icon: 'shop-fill',
  },
  {
    title: 'sidebar.stickies',
    key: 'stickies',
    to: '/stickies',
    icon: 'pin',
  },
  {
    title: 'sidebar.settings',
    key: 'settings',
    to: '/settings',
    icon: 'settings-grey',
  },
];

export const superadminSidebarOptions = [
  {
    title: 'sidebar.sales',
    key: 'sales',
    to: '/sales',
    icon: 'sales-chart',
  },
  {
    title: 'sidebar.merchants',
    key: 'merchants',
    to: '/merchants',
    icon: 'shop-fill',
  },
  {
    title: 'sidebar.staff',
    key: 'staff',
    to: '/staff',
    icon: 'users',
  },
  {
    title: 'sidebar.stickies',
    key: 'stickies',
    to: '/stickies',
    icon: 'pin',
  },
  {
    title: 'sidebar.settings',
    key: 'settings',
    to: '/settings',
    icon: 'settings-grey',
  },
  {
    title: 'sidebar.reports',
    key: 'reports',
    to: '/reports',
    icon: 'reports',
  },
];

export const merchantSidebarOptions = [
  {
    title: 'sidebar.dashboard',
    key: 'dashboard',
    icon: 'dashboard',
  },
  {
    title: 'sidebar.payments',
    key: 'payments',
    icon: 'payments',
  },
  {
    title: 'sidebar.clients',
    key: 'clients',
    icon: 'clients',
  },
  {
    title: 'sidebar.subscriptions',
    key: 'subscriptions',
    icon: 'subscriptions',
  },
  // {
  //   title: 'sidebar.wallet',
  //   key: 'wallet',
  //   icon: 'wallet',
  // },
];

<template>
  <a class="sidebar__help flex-center" tabindex="0">
    <AppIcon name="chat" size="18px" fill="var(--color-white)" />
    <AppText color="var(--color-white)">
      {{ t('common.help') }}?
    </AppText>
  </a>
</template>

<style scoped lang="scss">
.sidebar__help {
  width: 100%;
  height: 78px;
  gap: 4px;
  opacity: 0.4;
  @include transition-base(opacity);

  &:hover {
    opacity: 1;
  }
}
</style>

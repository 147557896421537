<template>
  <div
    class="sidebar__update"
    :class="{ active: isActive }"
    tabindex="0"
    @click="updatePage"
    @keypress.space="onSpacePress"
  >
    <AppIcon
      class="sidebar__update__icon"
      name="update"
      size="18px"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

import { useRoute } from '@/router';
import { emitter } from '@/composables/useBus';
import { clearAxiosCashedState } from '@/api';

export default defineComponent({
  setup() {
    const isActive = ref(false);

    const route = useRoute();

    const updatePage = () => {
      if (!isActive.value) {
        isActive.value = true;

        const routePath = route.path.replace('/', '');
        setTimeout(() => {
          const emitName = `update-${routePath}-page`;
          emitter.emit(emitName);
        }, 150);

        clearAxiosCashedState();

        setTimeout(() => {
          isActive.value = false;
        }, 300);
      }
    };

    const onSpacePress = (e) => {
      e.preventDefault();
      updatePage();
    };

    return {
      updatePage,
      onSpacePress,
      isActive,
    };
  },
});
</script>

<style scoped lang="scss">
.sidebar__update {
  box-sizing: border-box;
  position: absolute;
  z-index: 5;
  top: calc((80px - 42px) * 0.5);
  right: -21px;
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 50%;
  border: 2px solid var(--color-E6E6E6);
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition-base;
  &:focus-visible {
    @include focus-visible-style;
    border-radius: 50% !important;
  }

  &:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    .sidebar__update__icon {
      :deep(path) {
        fill: var(--color-white);
        @include transition-base('fill');
      }
    }
  }

  .sidebar__update__icon {
    :deep(path) {
      fill: var(--color-black-04);
    }
  }

  &.active {
    .sidebar__update__icon {
      transition: transform linear 300ms;
      transform: rotate(180deg);
    }
  }

}
</style>

<template>
  <div class="wallet-section">
    <div
      class="d-flex justify-content-between"
      :style="`height: 40px; margin-bottom: ${isWalletConnected ? '-10px' : '12px'};`"
    >
      <AppText color="var(--color-white-04)" type="uppercase" size="11px" pt="14px">
        {{ t('sidebar.wallet.label') }}
      </AppText>

      <FDropdown v-if="isWalletConnected" placement="bottom" block-class="wallet-popper">
        <template #title>
          <AppIcon name="options" size="18px" fill="var(--color-white)" />
        </template>

        <template #content>
          <DropdownItem size="small" no-paddings @click="downloadWalletData">
            <AppIcon name="download" size="16px" fill="var(--color-white)" style="margin-right: 12px; margin-top: 1px;" />
            <AppText pr="4px" color="var(--color-white)">
              {{ t('sidebar.wallet.downloadData') }}
            </AppText>
          </DropdownItem>

          <DropdownItem size="small" no-paddings @click="openModalToDisconnect">
            <AppIcon name="minus-circle" size="16px" fill="var(--color-white)" style="margin-right: 12px; margin-top: 1px;" />
            <AppText pr="4px" color="var(--color-white)">
              {{ t('sidebar.wallet.disconnect') }}
            </AppText>
          </DropdownItem>
        </template>
      </FDropdown>
    </div>
    <FButton
      v-if="!isWalletConnected"
      type="plain"
      class="setup-wallet"
      to="/wallet-setup"
      :class="{
        'is-current-route': currentRoute === 'wallet-setup',
      }"
    >
      {{ t('sidebar.wallet.setupWallet') }}
      <AppIcon name="arrow-bracket" class="rotate-90" fill="var(--color-white)" size="18px" />
    </FButton>
  </div>

  <template v-if="isWalletConnected">
    <SidebarItem
      :item="{
        title: 'sidebar.wallet.publicAddress',
        key: 'wallet',
        to: '/wallet',
        icon: 'at-email',
      }"
      :current-route="currentRoute"
    >
      <div
        class="payments-dot"
        :class="{
          'is-green': isPublicPaymentsEnabled,
        }"
      />
    </SidebarItem>
  </template>

  <ModalToDisconnectWallet v-model="isModalOpen" />
</template>

<script>
import { computed, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import DropdownItem from '@/components/ElementUI/DropdownItem.vue';
import ModalToDisconnectWallet from '@/components/Modules/ModalToDisconnectWallet';

import { i18n } from '@/plugins/localization';
import { supportedNetworksFamily } from '@/common/data';
import { useStore } from '@/store';
import { useRoute } from '@/router';
import { useWallet } from '@/composables/useWallet';
import { downloadAsFile } from '@/utils/functions';

import { isModalOpen, openModalToDisconnect } from './useModalOpen';
import SidebarItem from './SidebarItem.vue';

export default defineComponent({
  components: {
    DropdownItem,
    SidebarItem,
    ModalToDisconnectWallet,
  },
  setup() {
    const { t } = i18n.global;
    const store = useStore();
    const { rawWallets } = storeToRefs(store);
    const { isWalletConnected, isPublicPaymentsEnabled } = useWallet();

    const route = useRoute();
    const currentRoute = computed(() => route.path?.replace('/', ''));

    const publicKeys = computed(() => rawWallets.value
      .map((coin) => {
        if (coin.coin === 'XMR' && coin.prvVkey) {
          return `\n
${coin.coin}:
prvVkey: ${coin.prvVkey}
pubSkey: ${coin.pubSkey}
pubVkey: ${coin.pubVkey}
\n`;
        }

        if (supportedNetworksFamily.includes(coin.coin)) {
          return `${coin.coin}: ${coin.primaryAddress}`;
        }

        return `${coin.coin}: ${coin.xpub}`;
      })
      .filter((el) => !el.includes('undefined'))
      .join('\n'));

    const downloadWalletData = () => {
      downloadAsFile({
        data: publicKeys.value,
        filename: t('merchantWallet.file.walletData'),
      });
    };

    return {
      isWalletConnected,
      currentRoute,

      isModalOpen,
      isPublicPaymentsEnabled,

      openModalToDisconnect,
      downloadWalletData,
    };
  },
});
</script>

<style scoped lang="scss">
.wallet-section {
  padding: 7px 10px 7px 28px;
}

:deep(.f-dropdown--title) {
  background-color: var(--color-white-0);
  padding: 5px;
  border-radius: 8px;
  @include transition-base(background-color);
  .icon {
    opacity: 0.4;
    @include transition-base(opacity);
  }

  &:hover {
    background-color: var(--color-white-01);
    .icon {
      opacity: 1;
    }
  }
  &:focus-visible {
    outline-color: var(--color-white) !important;
  }
}

.setup-wallet {
  height: 32px;
  padding: 0 30px !important;
  background-color: var(--color-white-01);
  color: var(--color-white);
  opacity: 1;
  max-width: 174px;

  @include transition-base('background-color, opacity');

  &:active, &:focus {
    background-color: var(--color-white-01);
    color: var(--color-white);
  }

  &:hover {
    background-color: var(--color-white-02);
    color: var(--color-white);
  }

  :deep(.icon) {
    margin-left: 6px !important;
  }

  &.is-current-route,
  &.is-current-route:active,
  &.is-current-route:focus,
  &.is-current-route:hover {
    background-color: var(--color-white-008);
    opacity: 0.3;
    cursor: default;
  }
}

.payments-dot {
  width: 9.5px;
  height: 9.5px;
  background-color: var(--color-716F80);
  border-radius: 50%;
  border: 1.5px solid var(--color-2B1C2E);

  position: absolute;
  left: 43px;
  top: 13px;

  @include transition-base(background-color);

  &.is-green {
    background-color: var(--color-green);
  }
}
</style>

<template>
  <aside class="sidebar__wrap">
    <SidebarUpdate />
    <section class="sidebar">
      <div class="sidebar__top">
        <div class="logo-wrapper">
          <SwitchLogo icon="switch-logo" />
        </div>
        <div class="sibebar__body">
          <div class="sidebar__items">
            <SidebarItem
              v-for="item in options"
              :key="item.key"
              :item="item"
              :current-route="currentRoute"
              :badge="item.key === 'stickies' ? active.length : ''"
            />
          </div>
        </div>
        <WalletSection v-if="isMerchantRole" />
        <ToolsSection v-if="isAdminRole" />
      </div>
      <SidebarHelp v-if="isMerchantRole" @click="isModalOpen = true" />
    </section>

    <ModalToContact v-model="isModalOpen" />
  </aside>
</template>

<script>
import { computed, ref, onBeforeMount } from 'vue';

import ModalToContact from '@/components/Modules/ModalToContact/ModalToContact.vue';

import { getActiveStickies } from '@/api/admin/stickies';
import { APP_ROLES } from '@/common/data';
import { useWallet } from '@/composables/useWallet';
import { useStickies } from '@/composables/useStickies';
import { useToken } from '@/composables/useToken';
import { useRoute } from '@/router';

import SwitchLogo from './SwitchLogo.vue';
import SidebarItem from './SidebarItem.vue';
import SidebarUpdate from './SidebarUpdate.vue';
import SidebarHelp from './SidebarHelp.vue';
import WalletSection from './WalletSection.vue';
import ToolsSection from './ToolsSection.vue';
import {
  adminSidebarOptions,
  merchantSidebarOptions,
  superadminSidebarOptions,
} from './sidebarOptions';

export default {
  name: 'AppSidebar',
  components: {
    SwitchLogo,
    SidebarUpdate,
    SidebarHelp,
    SidebarItem,
    WalletSection,
    ToolsSection,
    ModalToContact,
  },
  setup() {
    const { active } = useStickies();

    const options = computed(() => {
      switch (tokenRole.value) {
        case APP_ROLES.ADMIN:
          return adminSidebarOptions;
        case APP_ROLES.SUPER_ADMIN:
          return superadminSidebarOptions;
        case APP_ROLES.MERCHANT:
          return merchantSidebarOptions;
        default:
          return [];
      }
    });

    const route = useRoute();

    const currentRoute = computed(() => route.path?.replace('/', ''));

    const { tokenRole, isMerchantRole, isAdminRole } = useToken();
    const { isWalletConnected } = useWallet();
    const shouldConnectWallet = computed(() => tokenRole.value === APP_ROLES.MERCHANT && !isWalletConnected.value);

    onBeforeMount(() => {
      if ([APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN].includes(tokenRole.value)) {
        getActiveStickies();
      }
    });

    const isModalOpen = ref(false);

    return {
      options,
      currentRoute,
      isWalletConnected,

      isMerchantRole,
      isAdminRole,

      shouldConnectWallet,
      active,

      isModalOpen,
    };
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $adminHeight;
  width: $adminSidebarWidth;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--el-color-sidebar);

  &__wrap {
    position: relative;
    z-index: 5;
  }
}

:deep(.sidebar__logo) {
  width: 100%;
  justify-content: center;
}

.logo-wrapper {
  height: 79px;
  display: flex;
  align-items: center;
}

.sidebar__top {
  height: calc(100% - 79px);
}

.sibebar__body {
  border-top: 1px solid var(--color-white-01);
  padding-top: 8px;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
}
</style>

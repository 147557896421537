<template>
  <router-link
    :class="{
      sidebar__item_active: item.key === currentRoute,
    }"
    class="sidebar__item"
    :to="`/${item.key}`"
  >
    <AppIcon
      class="sidebar__item-icon"
      :size="item.iconSize || '20px'"
      :name="item.icon"
    />
    <slot />
    <div class="sidebar__block">
      <h2 class="sidebar__item__title">
        {{ t(item.title) }} <AppText v-if="badge" size="13px" class="badge font-medium">
          {{ badge }}
        </AppText>
      </h2>
    </div>
  </router-link>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    currentRoute: {
      type: String,
      default: '',
    },
    badge: {
      type: [String, Number],
      default: '',
    },
  },
});
</script>

<style scoped lang="scss">
.sidebar__item {
  display: flex;
  align-items: center;
  height: 52px;
  padding: 13px 20px 13px 30px;
  cursor: pointer;

  position: relative;
  text-decoration: none;

  &:focus-visible {
    @include focus-visible-style;
    outline-offset: -10px;
    border-radius: 14px !important;
    outline-color: var(--color-white) !important;
  }

  &::before {
    content: '';
    background: var(--color-primary);
    border-radius: 0px 4px 4px 0px;
    width: 4px;
    height: 22px;

    position: absolute;
    left: 0;
    top: calc(50% - 11px);
    opacity: 0;

    @include transition-base (opacity);
  }

  &_active {
    &::before {
      opacity: 1;
    }
  }

  &__title {
    font-size: 15px;
    line-height: 1.2;
    color: #fff;
    opacity: 0.7;
    @include transition-base ('color, opacity');
  }
  &-icon {
    &:deep(.bg) {
      fill: #393939;
    }

    &:deep(.border) {
      display: none;
    }

    &:deep(.logo) {
      opacity: 0.4;
      @include transition-base ('opacity');
    }
  }

  &:hover, &_active {
    .sidebar__item__title {
      opacity: 1;
    }

    :deep(.icon .add-fill) {
      opacity: 1;
      fill: var(--color-white);
    }
    :deep(.icon .add-stroke) {
      opacity: 1;
      stroke: var(--color-white);
    }

    &:deep(.logo) {
      opacity: 1;
    }
  }

  .badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--color-white-007);
    min-width: 28px;
    height: 20px;
    margin-left: 16px;
    border-radius: 10px;
    opacity: 0.6;
  }
}
.sidebar__block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 18px;
}
</style>

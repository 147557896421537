<template>
  <div class="tools-section">
    <div
      class="d-flex justify-content-between"
    >
      <AppText
        color="var(--color-white-04)"
        type="uppercase"
        size="11px"
        :line-height="1"
        class="font-medium"
      >
        {{ t('sidebar.tools') }}
      </AppText>
    </div>
  </div>

  <SidebarItem
    class="sidebar__item-admin"
    :item="{
      title: 'sidebar.ethFlush',
      key: 'eth_flush',
      to: '/eth_flush',
      icon: 'eth-menu',
      iconSize: '24px',
    }"
    :current-route="currentRoute"
  />

  <SidebarItem
    class="sidebar__item-admin"
    :item="{
      title: 'sidebar.tronFlush',
      key: 'tron_flush',
      to: '/tron_flush',
      icon: 'tron-menu',
      iconSize: '24px',
    }"
    :current-route="currentRoute"
  />

  <SidebarItem
    class="sidebar__item-admin"
    :item="{
      title: 'sidebar.bscFlush',
      key: 'bsc_flush',
      to: '/bsc_flush',
      icon: 'bsc-menu',
      iconSize: '24px',
    }"
    :current-route="currentRoute"
  />

  <SidebarItem
    class="sidebar__item-admin"
    :item="{
      title: 'sidebar.resetCache',
      key: 'reset_cache',
      to: '/reset_cache',
      icon: 'network-menu',
      iconSize: '24px',
    }"
    :current-route="currentRoute"
  />
</template>

<script>
import { computed, defineComponent } from 'vue';

import { useRoute } from '@/router';

import SidebarItem from './SidebarItem.vue';

export default defineComponent({
  components: {
    SidebarItem,
  },
  setup() {
    const route = useRoute();
    const currentRoute = computed(() => route.path?.replace('/', ''));

    return { currentRoute };
  },
});
</script>

<style scoped lang="scss">
.tools-section {
  height: 44px;
  padding-top: 20px;
  padding-left: 30px;
  border-top: 1px solid var(--color-white-01);
  margin-top: 10px;
}

.sidebar__item-admin {
  height: 44px;

  :deep(.icon .icon-logo) {
    opacity: 0.5;

    @at-root .sidebar__item_active#{&}, .sidebar__item-admin:hover#{&} {
      opacity: 1;
    }
  }
}
</style>

<template>
  <div class="sidebar__logo flex-center">
    <div v-if="isMaintenanceOn" class="badge">
      <div />
      <div />
    </div>

    <router-link to="/" class="sidebar__logo-icon">
      <AppIcon
        :name="icon"
        size="54px"
        fill="var(--color-white)"
      />
    </router-link>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import { useAdmin } from '@/composables/useAdmin';

export default defineComponent({
  name: 'SwitchLogo',
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { adminSettings, role } = useAdmin();

    const isMaintenanceOn = computed(() => (['superadmin', 'admin'].includes(role?.value) && adminSettings?.value?.isMaintenanceEnabled));

    return {
      isMaintenanceOn,
      role,
    };
  },
});
</script>

<style scoped lang="scss">
.sidebar__logo {
  position: relative;
  &-icon {
    padding: 5px 20px;
    @include flex-center;
  }

  .badge {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 22px;
    left: 130px;
    background: var(--color-13171A);
    border-radius: 50%;
    @include flex-center;
    gap: 3px;
    @include transition-base('all');

    & > div {
      width: 4px;
      height: 11px;
      background: var(--color-error);
    }
  }
}
</style>
